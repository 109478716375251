define("ember-concurrency/-private/ember-environment", ["exports", "ember", "rsvp", "ember-concurrency/-private/external/environment", "@ember/debug", "@ember/runloop"], function (_exports, _ember, _rsvp, _environment, _debug, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmberEnvironment = _exports.EMBER_ENVIRONMENT = void 0;
  class EmberEnvironment extends _environment.Environment {
    assert() {
      (true && !(false) && (0, _debug.assert)(...arguments));
    }
    async(callback) {
      (0, _runloop.join)(() => (0, _runloop.schedule)('actions', callback));
    }
    reportUncaughtRejection(error) {
      (0, _runloop.next)(null, function () {
        if (_ember.default.onerror) {
          _ember.default.onerror(error);
        } else {
          throw error;
        }
      });
    }
    defer() {
      return (0, _rsvp.defer)();
    }
    globalDebuggingEnabled() {
      return _ember.default.ENV.DEBUG_TASKS;
    }
  }
  _exports.EmberEnvironment = EmberEnvironment;
  const EMBER_ENVIRONMENT = new EmberEnvironment();
  _exports.EMBER_ENVIRONMENT = EMBER_ENVIRONMENT;
});