define("ember-testing/lib/helpers/current_route_name", ["exports", "@ember/-internals/metal", "@ember/-internals/routing", "@ember/debug"], function (_exports, _metal, _routing, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = currentRouteName;

  /**
  @module ember
  */

  /**
    Returns the currently active route name.
  
  Example:
  
  ```javascript
  function validateRouteName() {
    equal(currentRouteName(), 'some.path', "correct route was transitioned into.");
  }
  visit('/some/path').then(validateRouteName)
  ```
  
  @method currentRouteName
  @return {Object} The name of the currently active route.
  @since 1.5.0
  @public
  */
  function currentRouteName(app) {
    (true && !(app.__container__) && (0, _debug.assert)('[BUG] app.__container__ is not set', app.__container__));

    var routingService = app.__container__.lookup('service:-routing');

    (true && !(routingService instanceof _routing.RoutingService) && (0, _debug.assert)('[BUG] service:-routing is not a RoutingService', routingService instanceof _routing.RoutingService));
    return (0, _metal.get)(routingService, 'currentRouteName');
  }
});