define("@ember/-internals/runtime/lib/system/object_proxy", ["exports", "@ember/-internals/runtime/lib/system/object", "@ember/-internals/runtime/lib/mixins/-proxy"], function (_exports, _object, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ObjectProxy extends _object.default {}

  ObjectProxy.PrototypeMixin.reopen(_proxy.default);
  var _default = ObjectProxy;
  _exports.default = _default;
});