define("ember-element-helper/helpers/element", ["exports", "@ember/component/helper", "@ember/debug", "@ember/component", "@embroider/util"], function (_exports, _helper, _debug, _component, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function UNINITIALIZED() {}
  class ElementHelper extends _helper.default {
    constructor() {
      super(...arguments);
      this.tagName = UNINITIALIZED;
      this.componentClass = null;
    }
    compute(params, hash) {
      (true && !(params.length === 1) && (0, _debug.assert)('The `element` helper takes a single positional argument', params.length === 1));
      (true && !(Object.keys(hash).length === 0) && (0, _debug.assert)('The `element` helper does not take any named arguments', Object.keys(hash).length === 0));
      let tagName = params[0];
      if (tagName !== this.tagName) {
        this.tagName = tagName;
        if (typeof tagName === 'string') {
          this.componentClass = (0, _util.ensureSafeComponent)(class DynamicElement extends _component.default {
            constructor() {
              super(...arguments);
              _defineProperty(this, "tagName", tagName);
            } // eslint-disable-line ember/require-tagless-components
          }, this);
        } else {
          this.componentClass = null;
          (0, _debug.runInDebug)(() => {
            let message = 'The argument passed to the `element` helper must be a string';
            try {
              message += ` (you passed \`${tagName}\`)`;
            } catch (e) {
              // ignore
            }
            (true && !(tagName === undefined || tagName === null) && (0, _debug.assert)(message, tagName === undefined || tagName === null));
          });
        }
      }
      return this.componentClass;
    }
  }
  _exports.default = ElementHelper;
});