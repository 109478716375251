define("@ember/-internals/runtime/lib/mixins/mutable_enumerable", ["exports", "@ember/-internals/runtime/lib/mixins/enumerable", "@ember/-internals/metal"], function (_exports, _enumerable, _metal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MutableEnumerable = _metal.Mixin.create(_enumerable.default);

  var _default = MutableEnumerable;
  _exports.default = _default;
});