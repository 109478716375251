define("@ember/runloop/type-tests.ts/join.test", ["@ember/runloop", "expect-type"], function (_runloop, _expectType) {
  "use strict";

  class Foo {
    test(_foo, _bar, _baz) {
      return 1;
    }

  }

  var foo = new Foo(); // With only function

  (0, _expectType.expectTypeOf)((0, _runloop.join)((_foo, _bar, _baz) => {
    return 1;
  }, 1, true)).toEqualTypeOf();
  (0, _expectType.expectTypeOf)((0, _runloop.join)((_foo, _bar, _baz) => {
    return 1;
  }, 1, true, 'string')).toEqualTypeOf();
  (0, _runloop.join)(_foo => {
    return 1; // @ts-expect-error invalid argument
  }, 'string'); // With target and function

  (0, _expectType.expectTypeOf)((0, _runloop.join)(foo, function (_foo, _bar, _baz) {
    (0, _expectType.expectTypeOf)(this).toEqualTypeOf();
    return 1;
  }, 1, true)).toEqualTypeOf();
  (0, _expectType.expectTypeOf)((0, _runloop.join)(foo, function (_foo, _bar, _baz) {
    return 1;
  }, 1, true, 'string')).toEqualTypeOf(); // @ts-expect-error invalid args

  (0, _runloop.join)(foo, function (_foo, _bar, _baz) {
    return 1;
  }, 1, 'string'); // With function string reference

  (0, _expectType.expectTypeOf)((0, _runloop.join)(foo, 'test', 1, true)).toEqualTypeOf();
  (0, _expectType.expectTypeOf)((0, _runloop.join)(foo, 'test', 1, true, 'string')).toEqualTypeOf(); // @ts-expect-error Invalid args

  (0, _runloop.join)(foo, 'test', 'string');
});